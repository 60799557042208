<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>Asignar limites</strong>
      </CCardHeader>
      <CCardBody>
        <CForm>
          <CRow class="form-input-row">
            <CCol sm="2">Limite mínimo</CCol>
            <CCol sm="7">
              <CInput
                id="minimumLimit"
                type="number"
                description="Límite mínimo"
                horizontal
                min="0"
                :value="minimumLimit"
                v-model="minimumLimit"
              />
            </CCol>
          </CRow>
          <CRow class="form-input-row">
            <CCol sm="2">Limite máximo</CCol>
            <CCol sm="7">
              <CInput
                id="maximumLimit"
                type="number"
                description="Límite máximo"
                horizontal
                min="0"
                :value="maximumLimit"
                v-model="maximumLimit"
              />
            </CCol>
          </CRow>
        </CForm>
        <div class="d-flex justify-content-center">
          <CButton
            class="btn btn-secondary"
            style="margin-right: 1em"
            @click="back()"
            >Volver</CButton
          >
          <CButton class="btn btn-success active" @click="addLimits()"
            >Asignar</CButton
          >
        </div>

        <CRow class="justify-content-center">
          <CCol md="9" class="p-4">
            <CAlert
              color="danger"
              dismissible
              :show.sync="contAlertParams"
              close-button
            >
              <strong>AVISO</strong>
              {{ errorText }}
              <CProgress
                :max="10"
                :value="contAlertParams"
                height="3px"
                color="danger"
                animated
              />
            </CAlert>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import config from "@/config";
import { checkUser, getUserLimit } from "../../utils/utils";
export default {
  name: "assignLimits",
  data: () => ({
    URL_user_id: -99,
    minimumLimit: 0,
    maximumLimit: 0,
    contAlertParams: 0,
    errorText: 'Error con alguno de los campos',
  }),
  created() {
    if (!checkUser()) return;
    this.receiveData();
  },
  methods: {
    receiveData() {
      this.getUserID();
      this.getLimits();
    },
    async getUserID() {
      if (!this.$route.params.id == this.URL_user_id) return;
      this.URL_user_id = this.$route.params.id;
    },
    getLimits() {
      let data = getUserLimit(this.URL_user_id);
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
          this.minimumLimit = result.user_limit.min_limit;
          this.maximumLimit = result.user_limit.max_limit;
      });
    },
    async addLimits() {
      let minLimit = document.getElementById("minimumLimit").value;
      let maxLimit = document.getElementById("maximumLimit").value;
      if (parseInt(minLimit) < 0 || parseInt(maxLimit) < 0) {
        this.contAlertParams = 10;
        this.errorText = "Los campos no pueden ser negativos";
        return;
      }
      if (parseInt(minLimit) == null || parseInt(maxLimit) == null) {
        this.contAlertParams = 10;
        this.errorText = "Error con alguno de los campos";
        return;
      }if (parseInt(minLimit)==parseInt(maxLimit)){
        this.contAlertParams = 10;
        this.errorText = "El límite mínimo no puede ser igual que el límite máximo";
        return;
      }
      if (parseInt(minLimit)>parseInt(maxLimit)){
        this.contAlertParams = 10;
        this.errorText = "El límite mínimo no puede ser mayor que el límite máximo";
        return;
      }
      if (parseInt(minLimit)>99999){
        this.contAlertParams = 10;
        this.errorText = "El límite mínimo es demasiado alto";
        return;
      }
      if (parseInt(maxLimit)>99999){
        this.contAlertParams = 10;
        this.errorText = "El límite máximo es demasiado alto";
        return;
      }
    const token = localStorage.token
     await axios.patch(config.BACK_IP + "/limit/" + this.URL_user_id, { min_limit: this.minimumLimit, max_limit: this.maximumLimit }, {headers : { token: token }} ).then(() => {
          
        
          this.$router.push("/user/");
        }).catch((error) => {
          if (error.response) {
            if (error.response.status == 401) {
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              window.location = "#/pages/404";
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });
        
    },
    back(){
      this.$router.push("/user/");
    }
  },
};
</script>